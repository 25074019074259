import axios, { AxiosError } from 'axios';
import { Storage, getLocalStorage, getCookieStorage, CookieStorage, COOKIE_PREFIX_KEY } from '../storage';
import { Subject, Observable } from 'rxjs';
import qs from 'query-string';
import { Logger } from '../logging';
import { createLoggerObject } from './utils';
import globalWindow from '@shared/core/globals';

const FIVE_SECONDS = 5;
const SSR_TOKEN_KEY = 'SsrAuthToken';
export const SSR_TOKEN_COOKIE = COOKIE_PREFIX_KEY + SSR_TOKEN_KEY;

import { executeWithAuth0Retry } from './auth0.retry';
import { windowExists } from '@shared/utils/windowExists';

export interface AccessToken
  extends Readonly<{
    id_token: string;
    bearer_type: string;
    expires_in: number;
  }> {}

interface Secrets {
  accessToken?: AccessToken;
  accessTokenClientId?: string;
  offset?: number;
  /** Required when requesting a new Access Token w/o re-authenticating the user. */
  refreshToken?: string;
  refreshTokenClientId?: string;
}

interface PersistedSecrets extends Pick<Secrets, 'accessTokenClientId' | 'offset' | 'refreshToken' | 'refreshTokenClientId'> {
  accessToken?: string;
}

export interface DecodedToken
  extends Readonly<{
    aud: string;
    azp: string;
    exp: number;
    iat: number;
    iss: string;
    sub: string;
    acr?: string;
    amr?: string[];
  }> {}

const category = 'joy-web.Auth';

const initializeTokenLogger = createLoggerObject({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refreshTokenError: (msg: string, error: unknown, extra: any = {}) => ({
    category,
    action: 'RefreshToken',
    actionType: 'error',
    extraInfo: { message: msg, error, ...extra }
  }),
  ensureSecretsFormatError: (error: unknown) => ({
    category,
    action: 'EnsureSecretsFormat',
    actionType: 'error',
    extraInfo: { error }
  }),
  refreshTokenFirstAttempt: () => ({
    category,
    action: 'RefreshTokenRetry'
  })
});

export class TokenService {
  private logger: ReturnType<typeof initializeTokenLogger>;
  private readonly accessTokenSubject: Subject<AccessToken | null>;
  private generateAccessTokenPromise: Promise<AccessToken | null> | null = null;
  private secondsOkToUseTokenBeforeExpiry: number = 1000; // 16.67 minutes
  private refreshTimeoutId: number | undefined;
  private secrets: Secrets = {};
  private readonly storage: Storage;
  private readonly cookieStorage: CookieStorage | undefined;

  constructor(logger: Logger) {
    this.logger = initializeTokenLogger(logger);
    this.accessTokenSubject = new Subject();
    this.storage = getLocalStorage();
    this.cookieStorage = getCookieStorage();
    this.authenticate();
  }

  public accessTokenObservable(): Observable<AccessToken | null> {
    return this.accessTokenSubject;
  }

  public authenticate(): void {
    this.checkTokenExpirationAndQueueRefresh();
  }

  public getToken(): string | null {
    // collect secrets to ensure the in-memory token matches the one in the storage
    // COMMENTED OUT: Seems like this may be causing a regression in createwedding
    // this.collectSavedSecrets();
    return this.getAccessToken();
  }

  public getTokenAsync = async (): Promise<string | null> => {
    // checkTokenExpirationAndQueueRefresh already collects secrets to ensure the in-memory token matches the one in the storage
    await this.checkTokenExpirationAndQueueRefresh();
    return this.getAccessToken();
  };

  public setToken(token: AccessToken | null, clientId: string, refreshToken?: string, serverTime?: number) {
    this.writeToSecretsAndAlertListeners(token, clientId, refreshToken, serverTime);
    this.clearRefreshTimeoutId();
    if (token) {
      this.checkTokenExpirationAndQueueRefresh();
    }
  }

  public deleteSecrets() {
    this.storage.setItem('secrets', undefined);
    this.clearTokenFromCookiesForSsrRendering();
    this.clearRefreshTimeoutId();
  }

  public hasValidMfaClaim() {
    const idToken = this.getDecodedAccessToken();

    if (idToken == null) {
      return false;
    }

    const { iat, amr } = idToken;
    const fifteenMinutes = 900;

    const hasTokenMFAClaim = Boolean(amr?.includes('mfa'));
    const now = Math.floor(Date.now() / 1000);
    const isIssuedLessThan15Minutes = iat > now - fifteenMinutes;

    return hasTokenMFAClaim && isIssuedLessThan15Minutes;
  }

  private attemptToRefreshTokenAndWrite = async (): Promise<AccessToken | null> => {
    // If there isn't a promise, create and set it
    if (!this.generateAccessTokenPromise) {
      this.clearRefreshTimeoutId();
      this.generateAccessTokenPromise = new Promise<AccessToken | null>(async resolve => {
        let newAccessToken: AccessToken | null = this.secrets.accessToken || null;
        // Abort if missing the required API arguments.
        if (this.secrets.accessTokenClientId && this.secrets.refreshToken) {
          const searchParams = qs.stringify({
            client_id: this.secrets.accessTokenClientId,
            grant_type: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
            target: this.secrets.accessTokenClientId,
            scope: 'openid',
            api_type: 'app',
            refresh_token: this.secrets.refreshToken
          });

          try {
            const res = await executeWithAuth0Retry(
              () => axios.post<AccessToken | null>('https://withjoy.auth0.com/delegation', searchParams),
              currentAttempt => {
                if (currentAttempt === 1) {
                  this.logger.refreshTokenFirstAttempt();
                }
              }
            );
            newAccessToken = res.data;
            this.saveTokenToCookiesForSsrRendering(newAccessToken);
          } catch (err) {
            if ((err as AxiosError).response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              // Will want to check for retry headers
              // console.log(err.response.headers);
              this.logger.refreshTokenError('Server responded, but is unable to refresh the token', err);
            } else if ((err as AxiosError).request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              // console.log(err.request);
              this.logger.refreshTokenError('Request was made but no response was received', err);
            } else {
              // Something happened in setting up the request that triggered an Error
              this.logger.refreshTokenError('Unable to make request', err);
            }

            // Unable to refresh - have them re auth
            newAccessToken = null;
            this.secrets.refreshToken = undefined;
            this.clearTokenFromCookiesForSsrRendering();
          }
        }

        this.writeToSecretsAndAlertListeners(newAccessToken);
        resolve(newAccessToken);
      }).finally(() => {
        // Remove the promise and begin a new refresh cycle.
        this.generateAccessTokenPromise = null;
        if (this.secrets.accessTokenClientId && this.secrets.refreshToken) {
          this.checkTokenExpirationAndQueueRefresh();
        }
      });
    }

    // This problem will always resolve with either:
    // 1. the new access token
    // 2. the current token or null
    return this.generateAccessTokenPromise;
  };

  private checkTokenExpirationAndQueueRefresh = async (): Promise<AccessToken | null> => {
    // always grab the latest secrets from storage
    this.collectSavedSecrets();

    const decodedToken = this.getDecodedAccessToken();

    if (!decodedToken) {
      return this.attemptToRefreshTokenAndWrite();
    }

    const timeLeft = this.getTimeLeftInSecondsBeforeExpiration(decodedToken, this.secrets.offset);

    // token is expired
    // applying a FIVE_SECONDS buffer to help ensure the token is not expired when used
    if (timeLeft <= FIVE_SECONDS) {
      // Token has effectively expired - refresh immediately
      return this.attemptToRefreshTokenAndWrite();
    }

    // token is about to expire in secondsOkToUseTokenBeforeExpiry (default 16.67 minutes)
    if (timeLeft < this.secondsOkToUseTokenBeforeExpiry) {
      // Token is about to expire soon - refresh immediately, but return the current token
      this.attemptToRefreshTokenAndWrite();
      // token is still approximately valid (has more than FIVE_SECONDS left)
      return Promise.resolve(this.secrets.accessToken || null);
    }

    this.clearRefreshTimeoutId();
    this.refreshTimeoutId = globalWindow.setTimeout?.(() => {
      this.attemptToRefreshTokenAndWrite();
    }, (timeLeft - this.secondsOkToUseTokenBeforeExpiry) * 1000);

    return Promise.resolve(this.secrets.accessToken || null);
  };

  private broadcastTokenUpdate(nextToken: AccessToken | null): void {
    this.accessTokenSubject.next(nextToken);
  }

  private clearRefreshTimeoutId() {
    clearTimeout(this.refreshTimeoutId);
    this.refreshTimeoutId = 0;
  }

  private collectSavedSecrets(): void {
    const localStorageSecrets = this.storage.getItem('secrets');
    const hash = windowExists() ? qs.parse(window.location.hash) : null;
    const hashSecrets = hash?.secrets;

    // This is to support the hash based login to fix ticket https://withjoy.atlassian.net/browse/JOY-8012
    // in addition to getSecretsForHashBasedLogin.
    if (hashSecrets) {
      this.secrets = this.parseSecretsStringToJSON(hashSecrets as string);
      if (!this.secrets.accessToken) {
        // We don't have a token in the hash, so something bad must have happened
        this.logger.refreshTokenError('Missing access token from hash based login', null);
      }

      // Remove the hash from the url and save the secrets to the local storage
      delete hash.secrets;
      window.location.hash = qs.stringify(hash);
      history.replaceState('', document.title, window.location.pathname + window.location.search + window.location.hash);
      this.storage.setItem('secrets', this.prepareSecretsForWrite());
    }

    if (localStorageSecrets) {
      this.secrets = this.parseSecretsStringToJSON(localStorageSecrets);
    }

    if (!this.secrets) {
      this.secrets = {};
    }
  }

  /**
   * This method is being created to support the hash based login to fix ticket https://withjoy.atlassian.net/browse/JOY-8012
   * where Firefox users are not being able to use the add to joy snippet. Since the snippet is loaded inside an iframe,
   * due to new restrictions in Firefox, the cookies are not being read by this iframe. So we'll be sending the secrets
   * to the app using the hash in the url. The app will read the hash and use the secrets to login the user.
   * @returns a string that can be used to login to the app with a hash (empty string if there are no secrets)
   */
  public getSecretsForHashBasedLogin(): string {
    return this.secrets ? '#secrets=' + encodeURIComponent(JSON.stringify(this.secrets)) : '';
  }

  private getDecodedAccessToken(): DecodedToken | null {
    if (this.secrets.accessToken) {
      try {
        const { id_token } = this.secrets.accessToken;
        if (!id_token) {
          console.warn('No token to decode.');
          return null;
        }
        let decodedToken: DecodedToken;
        if (windowExists()) {
          decodedToken = JSON.parse(atob(id_token.split('.')[1]));
        } else {
          // For decoding the token on the server
          decodedToken = JSON.parse(Buffer.from(id_token.split('.')[1], 'base64').toString('ascii'));
        }
        return decodedToken;
      } catch (e) {
        console.error(`Unable to decode token.`);
        console.error(e);
        return null;
      }
    }
    return null;
  }

  private getAccessToken(): string | null {
    /**
     * TEMPORARY - REMOVE IN AFTER DATA COLLECTION (ask Print Team for details)
     * This is a temporary feature flag to disable the token check to gather data on the impact of expired tokens
     * on the user experience. This check should be removed once the data has been collected.
     */
    const disablePresendTokenCheck = this.storage.getItem('disablePresendTokenCheck');
    if (disablePresendTokenCheck === 'on') {
      return this.secrets.accessToken?.id_token ?? null;
    }
    // END TEMPORARY
    if (this.secrets.accessToken) {
      return this.isTokenExpired() ? null : this.secrets.accessToken.id_token;
    } else {
      return null;
    }
  }

  private getTimeLeftInSecondsBeforeExpiration(decodedToken: DecodedToken, offset: number = 0): number {
    // The following logic is extracted from our current JWT module
    return decodedToken.exp - Math.floor((Date.now() - offset) / 1000);
  }

  private isTokenExpired(): boolean {
    const decodedToken = this.getDecodedAccessToken();
    // Slightly inaccurate to say it's expired when the token doesn't exist
    return decodedToken ? this.getTimeLeftInSecondsBeforeExpiration(decodedToken) <= 0 : true;
  }

  private parseSecretsStringToJSON(secretsString: string): Secrets {
    try {
      const secrets = JSON.parse(secretsString);

      // we used to stringify access token itself so need to handle that case for backward compat
      // probably can remove this code after July 2020 (3 months from now)
      let accessToken = secrets.accessToken;
      if (typeof secrets.accessToken === 'string') {
        accessToken = JSON.parse(accessToken);
      }

      accessToken = accessToken || null;

      return { ...secrets, accessToken };
    } catch (e) {
      return {};
    }
  }

  private writeToSecretsAndAlertListeners(newToken: AccessToken | null, clientId?: string | null, refreshToken?: string, serverTime?: number): void {
    this.secrets.accessToken = newToken || undefined;

    if (refreshToken) {
      this.secrets.refreshToken = refreshToken;
    }

    if (clientId) {
      this.secrets.accessTokenClientId = clientId;
      this.secrets.refreshTokenClientId = clientId;
    }

    if (serverTime) {
      this.secrets.offset = Date.now() - serverTime;
    }

    this.storage.setItem('secrets', this.prepareSecretsForWrite());

    this.broadcastTokenUpdate(newToken);
  }

  private prepareSecretsForWrite = (): string => {
    const { accessToken, ...restSecrets } = this.secrets;

    const withStringifiedAccessToken: PersistedSecrets = {
      ...restSecrets
    };

    try {
      // All apps expect `accessToken` to be stringified when reading from local storage
      withStringifiedAccessToken.accessToken = JSON.stringify(accessToken);
    } catch (e) {
      this.logger.ensureSecretsFormatError(e);
    }
    return JSON.stringify(withStringifiedAccessToken);
  };

  private saveTokenToCookiesForSsrRendering = (token?: AccessToken | null) => {
    if (this.cookieStorage && token) {
      this.cookieStorage.setItem(SSR_TOKEN_KEY, JSON.stringify(token));
    }
  };

  private clearTokenFromCookiesForSsrRendering = () => {
    if (this.cookieStorage) {
      this.cookieStorage.setItem(SSR_TOKEN_KEY, undefined);
    }
  };
}
