/**
 * All used modules from `react-router-dom` will be exported from this file.
 * We should avoid directly importing from `react-router-dom` for consistent usage.
 */

import React, { forwardRef } from 'react';
import * as H from 'history';

// eslint-disable-next-line no-restricted-imports
export type { StaticContext } from 'react-router';

// Re-export types from respective libraries
// eslint-disable-next-line no-restricted-imports
export type { MemoryRouterProps, RedirectProps, RouteComponentProps, RouteProps, SwitchProps, LinkProps, NavLinkProps, StaticRouterProps } from 'react-router-dom';

// Re-export unmodified components + hooks
// eslint-disable-next-line no-restricted-imports
export { BrowserRouter, MemoryRouter, Redirect, Router, StaticRouter, Switch, matchPath, useLocation, useParams, useRouteMatch } from 'react-router-dom';

// eslint-disable-next-line no-restricted-imports
import {
  Link as LinkRR,
  NavLink as NavLinkRR,
  useHistory as useHistoryRR,
  Route as RouteRR,
  RouteComponentProps as RouteComponentPropsRR,
  RouteProps as RoutePropsRR,
  RouteChildrenProps as RouteChildrenPropsRR
} from 'react-router-dom';

import { FeatureFlagsContextType, useFeatureFlagsContext } from '../featureFlags';

type HistoryAction = 'push' | 'replace';
type LocationDescriptorFn<HistoryLocationState = H.LocationState> = (location: H.Location<HistoryLocationState>) => H.LocationDescriptor<HistoryLocationState>;

type MonkeyPatchNavigationFnArgs<HistoryLocationState, Action extends HistoryAction> = {
  history: H.History<HistoryLocationState>;
  action: Action;
  context: FeatureFlagsContextType;
};

function ensureFeatureFlagQueryParams<HistoryLocationState = H.LocationState>(
  to: H.LocationDescriptor<HistoryLocationState>,
  context: FeatureFlagsContextType
): H.LocationDescriptor<HistoryLocationState>;
function ensureFeatureFlagQueryParams<HistoryLocationState = H.LocationState>(
  to: H.LocationDescriptor<HistoryLocationState> | LocationDescriptorFn<HistoryLocationState>,
  context: FeatureFlagsContextType
): H.LocationDescriptor<HistoryLocationState> | LocationDescriptorFn<HistoryLocationState>;
function ensureFeatureFlagQueryParams<HistoryLocationState = H.LocationState>(
  to: H.LocationDescriptor<HistoryLocationState> | LocationDescriptorFn<HistoryLocationState>,
  context: FeatureFlagsContextType
) {
  if (typeof to === 'string') {
    to = context.formatToUrl(to);
  } else if (typeof to === 'object') {
    // Retain existing query params
    to.search = context.formatToUrl(`?${to.search || ''}`);
  } else if (typeof to === 'function') {
    const originalTo = to;
    to = location => {
      const descriptor = originalTo(location);
      // Descriptor is of type 'object' and calling ensureFeatureFlagQueryParams will not result in an infinite loop
      return ensureFeatureFlagQueryParams(descriptor, context);
    };
  }

  return to;
}

function monkeyPatchNavigationFn<Action extends HistoryAction, HistoryLocationState = H.LocationState>({
  history,
  action,
  context
}: MonkeyPatchNavigationFnArgs<HistoryLocationState, Action>) {
  const originalMethod = action === 'push' ? history.push : history.replace;
  return (to: H.LocationDescriptor<HistoryLocationState>, state?: HistoryLocationState) => {
    to = ensureFeatureFlagQueryParams(to, context);
    originalMethod.apply(history, ([to, state] as unknown) as Parameters<H.History<HistoryLocationState>[Action]>);
  };
}

function monkeyPatchHistoryNavigation(history: H.History, context: FeatureFlagsContextType) {
  const hmp = (history as unknown) as { isMonkeyPatched: boolean };
  if (!hmp.isMonkeyPatched) {
    hmp.isMonkeyPatched = true;
    history.push = monkeyPatchNavigationFn({ history, action: 'push', context });
    history.replace = monkeyPatchNavigationFn({ history, action: 'replace', context });
  }
}

export type UseHistoryReturn = ReturnType<typeof useHistory>;

export function useHistory<HistoryLocationState = H.LocationState>(): H.History<HistoryLocationState> {
  const history = useHistoryRR<HistoryLocationState>();
  const context = useFeatureFlagsContext();

  monkeyPatchHistoryNavigation(history, context);

  return history;
}

export const Link: LinkRR = forwardRef((props, ref) => {
  let { to } = props;
  const context = useFeatureFlagsContext();
  to = ensureFeatureFlagQueryParams(to, context);
  return <LinkRR {...props} ref={ref} to={to} />;
});
export const NavLink: NavLinkRR = forwardRef((props, ref) => {
  let { to } = props;
  const context = useFeatureFlagsContext();
  to = ensureFeatureFlagQueryParams(to, context);
  return <NavLinkRR {...props} ref={ref} to={to} />;
});

export function Route<T extends RoutePropsRR = RoutePropsRR>(props: T) {
  const { render, children } = props;
  const context = useFeatureFlagsContext();

  const handleRenderProp = (renderProps: RouteComponentPropsRR) => {
    monkeyPatchHistoryNavigation(renderProps.history, context);
    return render?.(renderProps);
  };

  const handleChildrenProp = (childrenProps: RouteChildrenPropsRR) => {
    if (typeof children === 'function') {
      monkeyPatchHistoryNavigation(childrenProps.history, context);
      return children(childrenProps);
    }

    return children;
  };

  const maybeUseRenderer = (shouldUse: boolean, fn: typeof handleChildrenProp | typeof handleRenderProp) => {
    return shouldUse ? fn : undefined;
  };

  // eslint-disable-next-line react/no-children-prop
  return <RouteRR {...props} children={maybeUseRenderer(!!children, handleChildrenProp)} render={maybeUseRenderer(!!render, handleRenderProp)} />;
}
