import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useBasicEventInfoQuery } from '@graphql/generated';
import { useSegmentGroupCallForEvent } from '@shared/core/analytics/useSegmentGroupCall';

import { EventInfo, EventInfoData } from './EventInfo.types';
import { InternalEventInfoProvider } from './internalEventInfoContext';
import { useIdentify } from '@shared/core';

/**
 * Loads event information and makes this data available to all its children.
 *
 * For more details, see [eventInfo/README.md](./README.md).
 */
export const EventInfoProvider: React.FC = ({ children }) => {
  const [eventHandle, setEventHandle] = useState<string | undefined>();

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const { data, error, loading } = useBasicEventInfoQuery({
    batchMode: 'off',
    variables: { eventHandle: eventHandle || '' },
    skip: !eventHandle
  });
  const eventByName = data?.eventByName;

  const { executeSegmentGroupCallForEvent } = useSegmentGroupCallForEvent();
  executeSegmentGroupCallForEvent(eventByName?.firebaseId, {
    postgresEventId: eventByName?.id,
    eventType: eventByName?.info.eventType
  });

  const { identify } = useIdentify();
  useEffect(() => {
    const firebaseId = eventByName?.firebaseId;
    if (firebaseId) {
      identify({
        lastFirebaseEventId: firebaseId
      });
    }
  }, [eventByName, executeSegmentGroupCallForEvent, identify]);

  const eventInfoData = useMemo<EventInfoData | undefined>(() => {
    const eventByName = data?.eventByName;
    if (!eventByName) {
      return undefined;
    }

    const { firebaseId, id, info, productVerticals } = eventByName;
    const { eventType, eventDisplayName, fianceeFirstName, ownerFirstName, locationInfo } = info;
    return {
      eventId: id,
      eventFirebaseId: firebaseId,
      eventType,
      eventDisplayName,
      ownerFirstName,
      fianceeFirstName,
      locationInfo,
      productVerticals
    };
  }, [data?.eventByName]);

  const eventInfo = useMemo<EventInfo>(() => {
    return isInitialLoad
      ? {
          loading: true
        }
      : {
          loading,
          eventHandle,
          error,
          eventInfo: eventInfoData
        };
  }, [error, eventHandle, eventInfoData, isInitialLoad, loading]);

  const onFinishLoadingEventInfo = useCallback(() => {
    setIsInitialLoad(false);
  }, []);

  return (
    <InternalEventInfoProvider
      value={{
        setEventHandle,
        eventInfo,
        onFinishLoadingEventInfo
      }}
    >
      {children}
    </InternalEventInfoProvider>
  );
};
