import { useContext, useEffect, useMemo } from 'react';
import { isEqual } from 'lodash-es';
import { AnalyticsContext } from '../context';
import { createContext } from './context';
import { createProvider } from './provider';
import { TrackFunctionMap, PageFunctionMap, UseTelemetryArgs, TelemetryObjectArgs } from './types';
import { wrapActionsInAnalytics, wrapPagesInAnalytics } from './utils';

export const createTelemetry = <C, A extends TrackFunctionMap, P extends PageFunctionMap, EnrichedExtraInfo extends Record<string, unknown>>(
  config: TelemetryObjectArgs<A, P>,
  context?: C,
  name?: string,
  extraInfo?: EnrichedExtraInfo
) => {
  const { pages, actions } = config;
  const TelemetryContext = createContext<A, Partial<C>>(actions, context);

  const TelemetryProvider = createProvider<A, Partial<C>>(TelemetryContext, actions, context);
  TelemetryProvider.displayName = name;

  let enrichedExtraInfo = (extraInfo || {}) as EnrichedExtraInfo;

  const enrichTelemetryExtraInfo = (extraInfo: EnrichedExtraInfo) => {
    if (!isEqual(enrichedExtraInfo, extraInfo)) {
      enrichedExtraInfo = { ...enrichedExtraInfo, ...extraInfo };
    }
  };

  const useTelemetry = (args: UseTelemetryArgs<C, P> = {}) => {
    const { contextUpdate, addToContext, page } = args;
    const { track: trackAnalytics, page: pageAnalytics } = useContext(AnalyticsContext);
    const { actions, context, updateContext } = useContext(TelemetryContext);

    const actionsWithAnalytics = useMemo(
      () => wrapActionsInAnalytics(trackAnalytics, actions, context, enrichedExtraInfo),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [trackAnalytics, actions, context, enrichedExtraInfo]
    );
    const pagesWithAnalytics = useMemo(
      () => wrapPagesInAnalytics(pageAnalytics, pages, context, enrichedExtraInfo),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [pageAnalytics, context, enrichedExtraInfo]
    );

    const hasContextUpdateValueChanged = !isEqual(context, contextUpdate);
    useEffect(() => {
      if (hasContextUpdateValueChanged && contextUpdate) {
        updateContext(contextUpdate);
      }
    }, [hasContextUpdateValueChanged, updateContext, contextUpdate]);

    const hasAddToContextValueChanged = !isEqual({ ...context, ...addToContext }, context);
    useEffect(() => {
      if (hasAddToContextValueChanged && addToContext) {
        updateContext(addToContext);
      }
    }, [hasAddToContextValueChanged, addToContext, updateContext]);

    useEffect(() => {
      if (pagesWithAnalytics && page) {
        pagesWithAnalytics[page]?.enter();
      }

      return () => {};
    }, [page, pagesWithAnalytics]);

    return useMemo(() => {
      return {
        ...actionsWithAnalytics,
        ...pagesWithAnalytics,
        updateContext,
        context
      } as const;
    }, [actionsWithAnalytics, pagesWithAnalytics, updateContext, context]);
  };

  return {
    TelemetryContext,
    TelemetryProvider,
    useTelemetry,
    enrichTelemetryExtraInfo
  };
};
