import React, { useEffect } from 'react';
import { QueryStringFeatures } from './QueryStringFeatures';
import { setup, RoutePath, SetupOverrides, useTranslation, getLocalStorage } from '@shared/core';
import { Setup } from './Setup';
import { Route, Switch } from '@react-router';
import { ErrorBoundary } from './ErrorBoundary';
import { ssrRoutes } from './router.ssr';
import { Helmet } from 'react-helmet-async';
import { useFreezeNavigation } from '@shared/utils/hooks/useFreezeNavigation';
import { EventRedirect } from '@shared/components/EventRedirect';
import { enableMapSet } from 'immer';
import { AccountRedirect } from '@shared/components/AccountRedirect';
import { FinishLoadingEventInfo } from '@shared/utils/eventInfo';
import { useFeatureValue } from '@shared/core/featureFlags';

enableMapSet();

export type AppProps = Readonly<
  Partial<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    routes: Array<RoutePath<any>>;
    helmetContext: Object;
    setupOverrides?: SetupOverrides;
    defaultScreen?: 'mobile' | 'tablet' | 'desktop';
  }>
>;

const Routes = ({ routes = ssrRoutes }: Pick<AppProps, 'routes'>) => {
  const { location } = useFreezeNavigation();
  return (
    <Switch location={location}>
      <Route
        exact={true}
        path="/eventredirect/:eventId"
        render={({ match, staticContext, location }) => {
          return match && <EventRedirect eventId={match.params.eventId} location={location} staticContext={staticContext} />;
        }}
      />
      <Route exact={true} path="/account-redirect" render={() => <AccountRedirect />} />
      {routes.map(route => (
        <Route key={route.path} path={route.path} render={route.component} />
      ))}
    </Switch>
  );
};

const AppRoot: React.FC = ({ children }) => {
  const { t2 } = useTranslation('ssrServer');
  useTranslation('joykit');
  const translations = t2('metatags');

  /* TEMPORARY - REMOVE IN AFTER DATA COLLECTION (ask Print Team for details)
   * This is a temporary feature flag to disable the token check to gather data on the impact of expired tokens
   * on the user experience. This feature flag should be removed once the data has been collected.
   */
  const storage = getLocalStorage();
  const { value, loading } = useFeatureValue('disablePresendTokenCheck');
  useEffect(() => {
    storage.setItem('disablePresendTokenCheck', value);
  }, [storage, value, loading]);
  // END TEMPORARY
  return (
    <>
      <QueryStringFeatures />
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta property="og:title" content={translations.title} />
        <meta property="og:site_name" content="Joy" />
        <meta property="og:url" content="https://withjoy.com" />
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
      </Helmet>
      {children}
    </>
  );
};

const App: React.FC<AppProps> = ({ defaultScreen, routes, helmetContext = {}, setupOverrides }) => {
  const setupConfig = setup(setupOverrides);
  return (
    <>
      <ErrorBoundary>
        <Setup {...setupConfig} defaultScreen={defaultScreen} helmetContext={helmetContext}>
          <AppRoot>
            <Routes routes={routes} />
            <FinishLoadingEventInfo />
          </AppRoot>
        </Setup>
      </ErrorBoundary>
    </>
  );
};

export default App as React.FC<AppProps>;
