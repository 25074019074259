import { PrimitiveColorTokens } from '@withjoy/joykit/theme';

export const primitiveColorTokens: PrimitiveColorTokens = {
  transparent: 'transparent',
  white: '#FFFFFF',
  black: '#333333',

  // Monochrome
  gray1: '#FBFBFB',
  gray2: '#F7F7F7',
  gray3: '#EBEBEB',
  gray4: '#E0E0E0',
  gray5: '#D9D9D9',
  gray6: '#BEBEBE',
  gray7: '#A6A6A6',
  gray8: '#949494',
  gray9: '#808080',
  gray10: '#767676',
  gray11: '#666666',
  gray12: '#595959',
  gray13: '#4D4D4D',
  gray14: '#333333',

  // Purple
  purple1: 'rgba(250, 247, 255, 1)',
  purple2: 'rgba(238, 235, 255, 1)',
  purple3: 'rgba(217, 217, 255, 1)',
  purple4: 'rgba(208, 204, 255, 1)',
  purple5: 'rgba(191, 178, 255, 1)',
  purple6: 'rgba(113, 57, 191, 1)',
  purple7: 'rgba(95, 38, 155, 1)',
  purple8: 'rgba(80, 32, 128, 1)',
  purple9: 'rgba(68, 0, 102, 1)',

  // Green
  green1: 'rgba(247, 255, 253, 1)',
  green2: 'rgba(232, 255, 248, 1)',
  green3: 'rgba(163, 245, 220, 1)',
  green4: 'rgba(108, 224, 204, 1)',
  green5: 'rgba(0, 199, 186, 1)',
  green6: 'rgba(0, 148, 138, 1)',
  green7: 'rgba(23, 111, 105, 1)',
  green8: 'rgba(6, 64, 57, 1)',
  green9: 'rgba(0, 39, 27, 1)',

  // Red
  red1: 'rgba(255, 247, 248, 1)',
  red2: 'rgba(255, 229, 234, 1)',
  red3: 'rgba(245, 163, 177, 1)',
  red4: 'rgba(224, 108, 127, 1)',
  red5: 'rgba(215, 66, 90, 1)',
  red6: 'rgba(197, 0, 32, 1)',
  red7: 'rgba(153, 0, 25, 1)',
  red8: 'rgba(77, 0, 12, 1)',
  red9: 'rgba(51, 0, 8, 1)',

  // Humana
  humana1: 'rgba(255, 249, 245, 1)',
  humana2: 'rgba(255, 242, 232, 1)',
  humana3: 'rgba(255, 216, 187, 1)',
  humana4: 'rgba(249, 182, 134, 1)',
  humana5: 'rgba(224, 148, 109, 1)',
  humana6: 'rgba(182, 94, 56, 1)',
  humana7: 'rgba(107, 46, 46, 1)',
  humana8: 'rgba(77, 0, 0, 1)',
  humana9: 'rgba(51, 0, 0, 1)',

  // Orange
  orange1: 'rgba(255, 251, 247, 1)',
  orange2: 'rgba(255, 243, 229, 1)',
  orange3: 'rgba(255, 207, 151, 1)',
  orange4: 'rgba(255, 185, 102, 1)',
  orange5: 'rgba(255, 161, 51, 1)',
  orange6: 'rgba(255, 138, 0, 1)',
  orange7: 'rgba(178, 96, 0, 1)',
  orange8: 'rgba(77, 41, 0, 1)',
  orange9: 'rgba(58, 28, 0, 1)',

  // Brand Purple
  brandPurple1: 'rgba(251,247,255,1)',
  brandPurple2: 'rgba(241,235,255,1)',
  brandPurple3: 'rgba(229,217,255,1)',
  brandPurple4: 'rgba(95,38,152,1)',
  brandPurple5: 'rgba(51,0,102,1)',
  brandPurple6: 'rgba(32,0,65,1)',

  // Brand Blue
  brandBlue1: 'rgba(247,252,255,1)',
  brandBlue2: 'rgba(235,247,255,1)',
  brandBlue3: 'rgba(211,231,255,1)',
  brandBlue4: 'rgba(122,152,255,1)',
  brandBlue5: 'rgba(79,107,203,1)',
  brandBlue6: 'rgba(0,8,76,1)',

  // Brand Green
  brandGreen1: 'rgba(248,255,247,1)',
  brandGreen2: 'rgba(235,255,232,1)',
  brandGreen3: 'rgba(201,250,212,1)',
  brandGreen4: 'rgba(0,146,137,1)',
  brandGreen5: 'rgba(23,111,105,1)',
  brandGreen6: 'rgba(0,39,27,1)',

  // Brand Yellow
  brandYellow1: 'rgba(255, 252, 247, 1)',
  brandYellow2: 'rgba(255,245,229,1)',
  brandYellow3: 'rgba(255,237,209,1)',
  brandYellow4: 'rgba(255,160,13,1)',
  brandYellow5: 'rgba(188,129,41,1)',
  brandYellow6: 'rgba(80,50,2,1)',

  // Brand Red
  brandRed1: 'rgba(255,248,247,1)',
  brandRed2: 'rgba(255,236,235,1)',
  brandRed3: 'rgba(255,215,212,1)',
  brandRed4: 'rgba(255,100,89,1)',
  brandRed5: 'rgba(178,54,74,1)',
  brandRed6: 'rgba(102,0,55,1)',

  // Brand Humana
  brandHumana1: 'rgba(255,243,232,1)',
  brandHumana2: 'rgba(246,217,196,1)',
  brandHumana3: 'rgba(230,182,155,1)',
  brandHumana4: 'rgba(167,118,97,1)',
  brandHumana5: 'rgba(89,50,42,1)',
  brandHumana6: 'rgba(67,33,33,1)',

  // Brand Warm Gray
  brandWarmGray1: '#F9F5F0',
  brandWarmGray2: '#DCD4C9',
  brandWarmGray3: '#BBB1A3',
  brandWarmGray4: '#767676',
  brandWarmGray5: '#807566',
  brandWarmGray6: '#5A544A',
  brandWarmGray7: '#2C2925'
};
